var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import UserCaseAllot from "~/components/case-info/user-case-allot.vue";
import UserCaseBatchAllot from "~/components/case-info/user-case-batch-allot.vue";
import UserCaseBatchDistribute from "~/components/case-info/user-case-batch-distribute.vue";
import { Layout } from "~/core/decorator";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
var AlreadyDistributeWaitIssue = /** @class */ (function (_super) {
    __extends(AlreadyDistributeWaitIssue, _super);
    function AlreadyDistributeWaitIssue() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 个人未下发
        _this.caseIssuedFlag = "PERSONAL_UN_ISSUED";
        _this.selectionList = [];
        _this.batchNumberList = [];
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            casePool: _this.caseIssuedFlag,
            batchNumber: "",
            name: "",
            cardNo: "",
            clearAccountRMB: "",
            city: "",
            handsNumber: "" // 手别
        };
        _this.dataSet = [];
        _this.dialog = {
            personalManualDivision: false,
            batchDistribution: false,
            batchDivision: false
        };
        return _this;
    }
    /**
     * 钩子函数
     */
    AlreadyDistributeWaitIssue.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 刷新数据列
     */
    AlreadyDistributeWaitIssue.prototype.refreshData = function () {
        var _this = this;
        // 个人未下发
        this.distributeCaseService
            .getBatchNumberByCaseIssuedFlag(this.caseIssuedFlag)
            .subscribe(function (data) { return (_this.batchNumberList = data); });
        this.loading.state = true;
        this.distributeCaseService
            .searchDetalPersonalDisCase(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) { return (_this.dataSet = data); });
    };
    /**
     * 搜索条件重置
     */
    AlreadyDistributeWaitIssue.prototype.reset = function () {
        this.searchModel = {
            casePool: "",
            principalId: "",
            batchNumber: "",
            delegationDate: "",
            endCaseDate: "",
            name: "",
            caseNumber: "",
            city: "",
            handsNumber: "" // 手别
        };
    };
    // 确认下发
    AlreadyDistributeWaitIssue.prototype.onConfirmIssued = function () {
        var _this = this;
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要下发的案件");
            return;
        }
        this.$confirm("确认执行下发操作吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            var idList = _this.selectionList.map(function (v) { return v.id; });
            var model = {
                // 批次号
                batchNumber: "",
                // 案件id集合
                caseIds: idList
            };
            _this.loading.state = true;
            _this.distributeCaseService.issuedPersonalCase(model, _this.loading).subscribe(function (data) {
                _this.$message.success("下发成功");
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    AlreadyDistributeWaitIssue.prototype.onBatchIssued = function () {
        this.dialog.batchDistribution = true;
    };
    AlreadyDistributeWaitIssue.prototype.onBatchIssuedOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["user-case-batch-distribute"];
            form.refreshData();
        });
    };
    AlreadyDistributeWaitIssue.prototype.onManualSeparate = function () {
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.dialog.personalManualDivision = true;
    };
    AlreadyDistributeWaitIssue.prototype.onPersonalManualDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["user-case-allot"];
            form.refreshData();
        });
    };
    /**
     * 批次分配
     */
    AlreadyDistributeWaitIssue.prototype.onBatchDivision = function () {
        this.dialog.batchDivision = true;
    };
    AlreadyDistributeWaitIssue.prototype.onBatchDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["user-case-batch-allot"];
            form.refreshData();
        });
    };
    __decorate([
        Dependencies(PageService)
    ], AlreadyDistributeWaitIssue.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], AlreadyDistributeWaitIssue.prototype, "sortService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], AlreadyDistributeWaitIssue.prototype, "distributeCaseService", void 0);
    AlreadyDistributeWaitIssue = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                UserCaseAllot: UserCaseAllot,
                UserCaseBatchAllot: UserCaseBatchAllot,
                UserCaseBatchDistribute: UserCaseBatchDistribute,
            }
        })
    ], AlreadyDistributeWaitIssue);
    return AlreadyDistributeWaitIssue;
}(Vue));
export default AlreadyDistributeWaitIssue;
